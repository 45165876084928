import React from 'react'

import SEO from 'src/components/seo'
import { Trans, withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import { IN_UK } from '../utils/constants'

import {
  Container,
  Column,
  Line,
  CurrentPage,
  PageLink,
  Text,
  ALink,
  PageTitle,
  TosSubtitle,
  Signature,
} from '../templates/ShopifyPage/styles'

const PstiCompliance = () => {
  const { signature } = useStaticQuery(graphql`
    query PstiComplianceQuery {
      signature: file(relativePath: { eq: "signature.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <SEO title="PSTI Statement of Compliance" description="" path="/psti-compliance/" />
      <Container>
        <Column>
          <div>
            <Line />
            <PageLink to="/privacy-policy/">
              <Trans>Privacy Policy</Trans>
            </PageLink>
            <Line />
            <PageLink to="/tos/">
              <Trans>Terms of Service</Trans>
            </PageLink>
            <Line />
            {IN_UK && (
              <>
                <CurrentPage to="/psti-compliance/">
                  <Trans>PSTI Statement of Compliance</Trans>
                </CurrentPage>
                <Line />
              </>
            )}
          </div>
        </Column>
        <Column>
          <PageTitle>
            <Trans>PSTI Statement of Compliance</Trans>
          </PageTitle>
          <TosSubtitle />

          <Text>Product Model: 150EA-UK, 150FA-UK, 100DA-UK, 102-FRM</Text>
          <Text>Product Name(s): Skylight Frame, Skylight Calendar</Text>
          <Text>Manufacturer: Glimpse LLC, dba, Skylight. </Text>
          <Text>Address: 101A Clay Street San Francisco, CA 94111 </Text>
          <Text>
            We, Glimpse LLC, dba, Skylight, hereby declare that the devices, as described above, are
            in conformity with the following relevant requirements in 2023 No. 1007 CONSUMER
            PROTECTION: The Product Security and Telecommunications Infrastructure (Security
            Requirements for Relevant Connectable Products) Regulations 2023: The deemed compliance
            conditions in Schedule 2.
          </Text>
          <Text>
            Hardware and software updates and support will be available for this product for two
            years beginning 15 July 2024, with updates made from time to time, communicated via
            in-app messaging.
          </Text>
          <Text>
            Users can report vulnerabilities to Skylight via{' '}
            <ALink href="mailto:help@skylightframe.com">help@skylightframe.com</ALink>
          </Text>
          <Text>Signature:</Text>
          <Signature image={signature.childImageSharp.gatsbyImageData} alt="signature" />
          <Text>Name: Michael Segal</Text>
          <Text>Function: CEO</Text>
          <Text>Location: USA</Text>
          <Text>Date of Issue: 2024/07/17</Text>
        </Column>
      </Container>
    </>
  )
}

export default withTranslation()(PstiCompliance)
